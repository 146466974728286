if(typeof jQuery === 'undefined'){
    document.write(unescape("%3Cscript src='js/jquery.min.js' type='text/javascript'%3E%3C/script%3E"));
}

$(document).ready(function($) {

	// ******** ANIMATION ON CLICK

	$('a[href^="#"]').on('click', function(event) {

        var target = $( $(this).attr('href') );

        if( target.length ) {
            event.preventDefault();
            $('html, body').stop(true, true).animate({
                scrollTop: target.offset().top
            }, 800);
        }
    });

	// ******** OPEN MENU ON MOBILE

     $('#burger--wrapper').click(function() {
         $('#burger').slideToggle();
      //   $('nav').toggleClass('menu-active');

     });
     $('.menu--item').click(function() {
         $('#burger').slideToggle();
      //   $('nav').removeClass('menu-active');
     });


    // ******** Object fit fix for IE or EDGE

    $(function () {
        objectFitImages()
    });


});

// ******** MENU SHRINK ON SCROLL

// function shrink() {
//     window.addEventListener('scroll', function(e){
//         var distanceY = window.pageYOffset || document.documentElement.scrollTop,
//             shrinkOn = 300;
//         if (distanceY > shrinkOn) {
//             $('header').addClass('header_shrink');
//             $('#burger').removeClass('active');
// 	        $('nav').removeClass('menu-active');
//         } else {
//         	$('header').removeClass('header_shrink');

//         }
//     });
// }

// ******** CHANGE MENU ITEM .active ON SCROLL, ALSO INCLUDES shrink();

// $(window).scroll(function() {
//     var scrollPos = $(document).scrollTop();
//     shrink();
//     $('.menu-item').each(function () {
//         var currLink = $(this);
//         var refElement = $(currLink.attr("href"));
//         if (refElement.position().top-100 <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
//             $('.menu-item').removeClass("sub_active");
//             currLink.addClass("sub_active");
//         }
//         else{
//             currLink.removeClass("sub_active");
//         }
//     });
// });
