/*============================================
      CALCULATING SCROLLMAGIC DURATIONS
============================================*/
var body = document.body,
    html = document.documentElement,
    documentH = 0, viewportW = 0, viewportH = 0, screenW = 0, headerH = 0,
    heightRelative2Width = 0, sectionH = 0, navSectionH = 0, headerD = 0,
    profil_partner_D = 0, oefenD = 0, fliesenD = 0, ausstellungD = 0;

window.addEventListener('load', function() {
  triggerDurations();
  updatingController();
});

window.addEventListener('resize', function() {
  triggerDurations();
  updatingController();
});

function triggerDurations() {
  documentH = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
  screenW = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  viewportH = (window.innerHeight > 0) ? window.innerHeight : html.clientHeight;
  viewportW = (window.innerWidth > 0) ? window.innerWidth : html.clientWidth;

  headerH = Math.round(viewportH * 0.15) + 20;
  heightRelative2Width = Math.round(viewportW * 0.4 - viewportW * 0.05);
  sectionH = heightRelative2Width + 10;// -10 of innerMargin, +20 of marginBottom
  navSectionH = Math.round(heightRelative2Width * 1.5) + 10;// navSections are 1.5x bigger

  headerD = (documentH - headerH * 2) - sectionH * 2;
  profil_partner_D = sectionH * 3;
  ausstellungD = sectionH * 4;
  oefenD = navSectionH + sectionH * 2;
  fliesenD = navSectionH + sectionH * 3;
}

/*============================================
                  CALLBACKS
============================================*/
function headerDuration() { return headerD; }
function profil_partner_Duration() { return profil_partner_D; }
function ausstellungDuration() { return ausstellungD; }
function oefenDuration() { return oefenD; }
function fliesenDuration() {return fliesenD; }
function bothDuration() { return sectionH; }

/*============================================
                    SETUP
============================================*/
var controller = new ScrollMagic.Controller();

/*============================================
                HEADER & FOOTER
============================================*/
var headerFooterAreActive = new ScrollMagic.Scene({
  triggerElement: "#header--trigger",
  duration: headerDuration
}).setClassToggle("header, footer", "active")
  .addTo(controller);

/*============================================
                    PROFIL
============================================*/
var profilIsActive = new ScrollMagic.Scene({
  triggerElement: "#profil--section",
  duration: profil_partner_Duration
}).setClassToggle("#profil--header", "active");

/*============================================
                  AUSSTELLUNG
============================================*/
var ausstellungIsActive = new ScrollMagic.Scene({
  triggerElement: "#ausstellung--section",
  duration: ausstellungDuration
}).setClassToggle("#ausstellung--header", "active");

/*============================================
                    OEFEN
============================================*/
var oefenIsActive = new ScrollMagic.Scene({
  triggerElement: "#oefen--section",
  duration: oefenDuration
}).setClassToggle("#oefen--nav a", "active");

var section10IsActive = new ScrollMagic.Scene({
  triggerElement: "#oefen--section",
  duration: oefenDuration
}).setClassToggle("#oefen--section>div:first-of-type", "active--right");

/*============================================
                  FLIESEN
============================================*/
var fliesenIsActive = new ScrollMagic.Scene({
  triggerElement: "#fliesen--section",
  duration: fliesenDuration
}).setClassToggle("#fliesen--nav a", "active");

var section13IsActive = new ScrollMagic.Scene({
  triggerElement: "#fliesen--section",
  duration: fliesenDuration
}).setClassToggle("#fliesen--section>div:first-of-type", "active--left");

/*============================================
                  PARTNER
============================================*/
var partnerIsActive = new ScrollMagic.Scene({
  triggerElement: "#partner--section",
  duration: profil_partner_Duration
}).setClassToggle("#partner--header", "active" );

var bothAreActive = new ScrollMagic.Scene({
  triggerElement: "#partner--section",
  duration: profil_partner_Duration
}).setClassToggle("a", "active" );

var section17IsActive = new ScrollMagic.Scene({
  triggerElement: "#both--section",
  duration: bothDuration
}).setClassToggle("#bg--half", "active--both");

/*============================================
                  KONTAKT
============================================*/
var kontaktIsActive = new ScrollMagic.Scene({
  triggerElement: "#kontakt--section"
}).setClassToggle("#kontakt--header", "active" );

/*=============================================
          UPDATING THE CONTROLLER
=============================================*/
function updatingController() {
  if (screenW < 1023 || viewportW < 1023) {
    controller.removeScene([
      profilIsActive,
      ausstellungIsActive,
      oefenIsActive,
      section10IsActive,
      fliesenIsActive,
      section13IsActive,
      partnerIsActive,
      bothAreActive,
      section17IsActive,
      kontaktIsActive
    ]);
    document.getElementsByTagName('FOOTER')[0].removeAttribute("class");
  } else {
    controller.addScene([
      profilIsActive,
      ausstellungIsActive,
      oefenIsActive,
      section10IsActive,
      fliesenIsActive,
      section13IsActive,
      partnerIsActive,
      bothAreActive,
      section17IsActive,
      kontaktIsActive
    ]);
  }
}
